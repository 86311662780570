<template>
  <div class="loginVercodePage">
    <div class="form">
      <div class="item">
        <h4 class="title">登录账号</h4>
        <div class="input-view float">
          <a-input
            v-model:value="mobile"
            placeholder="请输入手机号/登录账号"
          />
        </div>
      </div>
      <div class="item">
        <h4 class="title">登录密码</h4>
        <div class="input-view float">
          <a-input-password
            v-model:value="password"
            placeholder="请输入登录密码"
          />
        </div>
      </div>
      <div class="item">
        <h4 class="title">图形验证码</h4>
        <div class="input-view">
          <a-input-search
            v-model:value="imageCode"
            placeholder="请输入图形验证码"
            @search="changeCode"
          >
            <template #enterButton>
              <img :src="imageCodeUrl" alt="" />
            </template>
          </a-input-search>
        </div>
      </div>

      <a-button type="primary" :disabled="loginController" @click="login"
        >登录</a-button
      >
      <p class="changePassword" @click="handleChangePassword">更改密码？</p>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      imageCodeUrl: "",
      mobile: "",
      password: "",
      imageCode: "",
    };
  },
  mounted() {
    this.changeCode();
  },
  methods: {
    ...mapActions({
      setToken: "user/setToken",
      setUser: "user/setUser",
    }),
    async login() {
      if (this.loginController) return;
      const hide = this.$message.loading("登录中..", 0);
      try {
        let res = await this.$http.post(this.$interface.loginPassword, {
          mobile: this.mobile,
          imageCode: this.imageCode,
          password: this.password,
        });
        this.$message.success("登录成功");
        console.log(res.data.data);
        await this.setToken({
          token: res.data.data.authorization,
          remember: true,
        });
        await this.setUser({
          classInfo: res.data.data.classInfo,
          hostipalName: res.data.data.hostipalName,
          email: res.data.data.email,
          memberName: res.data.data.memberName,
          mobile: res.data.data.mobile,
          username: res.data.data.username,
          password: res.data.data.password,
        });
        this.goHome();
      } catch (err) {
        if (err.code == 2) {
          this.$emit("changeType", 3);
        }
      } finally {
        hide();
      }
    },
    goHome() {
      const query = this.$route.query;
      const path = query && query.from ? query.from : "/";
      this.$router.push("/").catch(() => {});
    },
    handleChangePassword() {
      this.$emit("changeType", 3);
    },
    /* 更换图形验证码 */
    async changeCode() {
      try {
        let res = await this.$http.get(this.$interface.loginImageCode, {
          withCredentials: true,
        });
        this.imageCodeUrl = res.data.data || "";
      } catch (err) {}
    },
  },
  computed: {
    loginController() {
      if (this.mobile && this.imageCode && this.password) return false;
      return true;
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>